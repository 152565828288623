<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta :content="title" name="description"></Meta>
  </Head>
  <LazyAppBarSempre/>
  <v-layout class="d-flex flex-column">
    <v-main>
      <v-container
          fluid
          style="padding: 0">
        <v-carousel
            class="carrossel"
            cycle
            hide-delimiter-background
            interval="5000"
            show-arrows="hover">
          <v-carousel-item
              cover
          >
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-B2B-MOBILE.png' : '/BANNER-B2B.png'"
                  alt="Internet para sua empresa. Soluções personalizadas para seu negócio."
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item
              cover
          >
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-SVAS-MOBILE.png' : '/BANNER-SVAS.png'"
                  alt="Tudo que você precisa em um só lugar. LEVEDUCA, Skeelo, Clube Certo, Kaspersky"
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item
              cover
          >
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-WATCH-MOBILE.png' : '/BANNER-WATCH.png'"
                  alt="TV ao vivo + Streaming. Paramount+ e HBO Max."
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item
              cover
          >
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile? '/SITE--DEEZER--MOBILE.png' : '/BANNER-DEEZER.png'"
                  alt="Conectado Sempre no seu ritmo. Deezer."
                  class="fill-height w-100"
                  placeholder>
              </NuxtImg>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item
              cover
          >
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-FRAUDE-MOBILE.png' : '/BANNER-fraude.png'"
                  alt="
                  Atenção. A Sempre Internet não solicita pagamentos via PIX de boletos abertos
                  ou em negociações através de plataformas de mensagem, como o Whatsapp.
                  Caso você receba algum tipo de mensagem com este conteúdo, favor desconsiderar.
                  Em caso de dúvidas, entre em contato com o nosso canal oficial: 0800 300 0800
                  "
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <v-container
            class="bg-primary text-center"
            fluid>
          <div class="py-10">
            <h2 class="text-secondary texto-destaque">INTERNET ULTRAVELOZ COM PLANOS SOB MEDIDA.</h2>
            <h3 class="text-white texto-comum">MILHARES DE CLIENTES SATISFEITOS. CHEGOU A SUA VEZ!</h3>
          </div>
          <div class="w-100 d-flex justify-center">
            <div
                v-if="carregandoAcoes"
                class="py-10 actions-container">
              <v-skeleton-loader
                  v-for="item in 4"
                  class="card-secondary"
                  height="180"
                  type="image, text"
                  width="180"
              ></v-skeleton-loader>
            </div>
            <div
                v-else
                class="py-10 actions-container">
              <LazyCardHome
                  v-for="acao of acoes"
                  :key="acao.titulo"
                  :acao="acao"/>
            </div>
          </div>
        </v-container>
      </v-container>
    </v-main>
    <LazyFooterSempre></LazyFooterSempre>
    <LazyWhatsappSempre/>
  </v-layout>
</template>
<script lang="ts">
import HeaderSempre from "~/components/header-sempre.vue";
import WhatsappSempre from "~/components/whatsapp-sempre.vue";

export interface Acao {
  titulo: string;
  imagem: string;
  cor: string;
  url: string;
}

export default defineComponent({
  components: {WhatsappSempre, HeaderSempre},
  data() {
    return {
      carregandoAcoes: true,
      title: 'Sempre Internet',
      acoes: [] as Acao[],
    }
  },
  async mounted() {
    try {
      const acoesData = await import('~/assets/data/acoes-home.json');
      this.acoes = acoesData.default;
    } catch (error) {
      console.error('Erro ao carregar ações:', error);
    } finally {
      this.carregandoAcoes = false;
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs;
    },
  },
})
</script>
<style scoped>
.actions-container {
  justify-items: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.carrossel {
  height: auto !important;
}

@media (max-width: 1264px) {
  .actions-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 960px) {
  .actions-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .actions-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.botao-area-assinante {
  border-radius: 10px !important;
}

.borda-branca {
  border: 2px solid white !important;
}

.card-acoes {
  border-radius: 10px !important;
  background-color: transparent !important;
}

.card-primary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-primary-lighten-1)) 0%, rgb(var(--v-theme-primary-darken-3)) 100%);
}

.card-secondary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-secondary-lighten-1)) 0%, rgb(var(--v-theme-secondary-darken-3)) 100%)
}

.telefone-contato {
  font-size: 36px;
  font-weight: 900;
}

.texto-comum {
  font-size: 31px;
  font-weight: 600;
  line-height: 1.3em;
}

.texto-destaque {
  font-size: 50px !important;
  font-weight: 900;
}

a:-webkit-any-link {
  color: white !important;
  transition: color linear 0.2s !important;
  text-decoration: none !important;
}

a:-webkit-any-link:hover {
  color: rgb(var(--v-theme-secondary)) !important;
  text-decoration: underline !important;
}
</style>
